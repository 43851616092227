import React, { useEffect } from 'react'
// import Helmet from 'react-helmet'
import browserLang from 'browser-lang'
import { navigate } from 'gatsby'
import Header from 'src/storyblok/GlobalComponents/Header/Header'
import Footer from 'src/storyblok/GlobalComponents/Footer/Footer'
import { Props } from './types'
import * as styles from './Layout.module.scss'
import { IntlProvider } from 'react-intl'

export default ({ children, pageContext, path }: Props): JSX.Element => {
  const { globalData, story }: any = pageContext ? pageContext : {}
  const { header, footer, graphics }: any = globalData ? globalData : {}

  const pageType = story?.field_component ? story.field_component : ''
  const title =
    pageType === 'page_event'
      ? story?.content?.date
      : pageType === 'page_content'
      ? story?.name
      : ''
  const useLogo =
    pageType === 'page' || pageType === 'page_english' ? false : true

  const language = pageContext ? pageContext.language : 'sv'
  const messages = pageContext ? pageContext.messages : {}

  // useEffect(() => {
  //   const storageKey = 'is-redirected'
  //   const isRedirected = localStorage.getItem(storageKey)

  //   if (!isRedirected) {
  //     localStorage.setItem(storageKey, 'true')
  //     const userLanguage = browserLang({
  //       languages: ['sv', 'en'],
  //       fallback: 'sv',
  //     })
  //     if (language !== userLanguage) {
  //       if (userLanguage === 'en') {
  //         navigate(`/en${path}`)
  //       } else {
  //         if (path === '/en') {
  //           navigate(path.replace('/en', '/'))
  //         } else {
  //           navigate(path.replace('en/', ''))
  //         }
  //       }
  //     }
  //   }
  // }, [])

  return (
    <IntlProvider locale={language} messages={messages}>
      <div className={styles.siteWrapper}>
        {header?.content && (
          <Header
            blok={header.content}
            title={title}
            useLogo={useLogo}
            className={
              pageType === 'page_content'
                ? styles.contentHeader
                : pageType === 'page'
                ? styles.pageHome
                : ''
            }
          />
        )}

        {/* {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )} */}
        {/* <SEO
          title={story?.name && story?.slug !== 'home' ? story.name : ''}
          metaDescription={seo_description}
          image={seo_image}
        /> */}

        {children}

        {footer?.content && (
          <Footer blok={footer.content} graphics={graphics} />
        )}
      </div>
    </IntlProvider>
  )
}
