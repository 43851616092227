import React, { useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './MarketHype.module.scss'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'
import { FormattedMessage } from 'react-intl'
import FlatButton from '../UI/FlatButton/FlatButton'

const Newsletter = () => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const target = document.getElementById('markethype-sidebar')
      if (window && window.mhForm) {
        window.mhForm.create({ formId: '64422b296363fd1930b6e34d', target })
      }
    }
  }, [])

  return (
    <React.Fragment>
      <Box
        className={classnames(
          styles.overlay,
          activeMenu === 'newsletter' ? styles.active : ''
        )}
        onClick={() => setActiveMenu('')}
      ></Box>
      <Flex
        className={
          activeMenu === 'newsletter'
            ? [styles.panel, styles.active].join(' ')
            : styles.panel
        }
        flexDirection="column"
        justifyContent={['flex-start', null, null, 'space-between']}
        paddingX={[6, null, null, 7]}
        paddingY={[6, null, null, 8]}
      >
        <Flex
          marginBottom={8}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box as="span" className={styles.label}>
            <FormattedMessage id="nyhetsbrev" defaultMessage="Nyhetsbrev" />
          </Box>
          <FlatButton
            onClick={() => setActiveMenu('')}
            style="small"
            purpleBorder
          >
            <FormattedMessage id="stäng" defaultMessage="Stäng" />
          </FlatButton>
        </Flex>
        <div id="markethype-sidebar"></div>
      </Flex>
    </React.Fragment>
  )
}

export default Newsletter
