import React from 'react'
import { Flex, Box } from 'reflexbox'

export const Container = (props: any) => <Box {...props} />

Container.defaultProps = {
  paddingX: [
    'var(--container-spacing-mobile)',
    null,
    null,
    'var(--container-spacing-desktop)',
  ],
  width: 1,
}

export const Row = (props: any) => <Flex {...props} />

Row.defaultProps = {
  marginX: ['-8px', null, null, '-24px'],
  flexDirection: ['column', null, null, 'row'],
  flexWrap: 'wrap',
}

export const Column = (props: any) => <Box {...props} />

Column.defaultProps = {
  position: 'realtive',
  width: '100%',
  minHeight: '1px',
  paddingX: [2, null, null, 6],
}
