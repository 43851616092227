import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import * as styles from './Header.module.scss'
import { Props } from './types'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import classnames from 'classnames'
import { Container } from 'src/components/UI/Grid/Grid'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import FlatButton from 'src/components/UI/FlatButton/FlatButton'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'
import { trackEvent } from 'src/helpers/tracking'
import ScrollLock from 'react-scrolllock'

const Header = ({ blok, className }: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  const { newsletter_button, ticket_link, label, main_nav, second_nav } = blok
  const { locale } = useIntl()

  return (
    <SbEditable content={blok}>
      {/* Overlay */}
      <Box
        className={classnames(
          styles.overlay,
          activeMenu === 'nav' ? styles.active : ''
        )}
        onClick={() => setActiveMenu('')}
      ></Box>

      {/* Header */}
      <Container
        as="header"
        className={classnames(styles.header, className ? className : '')}
      >
        <Flex
          height="100%"
          width={1}
          alignItems={['center', null, null, 'center']}
          justifyContent="space-between"
          className={styles.headerInner}
          paddingY={[4, null, null, 0]}
        >
          <Box width={['200px', null, null, 1 / 3]}>
            <LinkComponent
              className={classnames('headerTitle', styles.logotype)}
              to={'/'}
            >
              {'Rosendal'}
              <br />
              <span>{'Garden Party'}</span>
            </LinkComponent>
          </Box>
          <Flex
            as="nav"
            width={['auto', null, null, 1 / 3]}
            justifyContent={['flex-end']}
            alignItems={['center']}
            flexDirection={['row']}
            className={styles.navigation}
          >
            {ticket_link && (
              <Box className="hideMobile" marginRight={[2, null, null, 3]}>
                <FlatButton
                  className="use-editor"
                  href={ticket_link}
                  style="small"
                  shadow
                >
                  <FormattedMessage
                    id="köp-biljetter"
                    defaultMessage="Köp biljetter"
                  />
                </FlatButton>
              </Box>
            )}
            {newsletter_button && (
              <Box className="hideMobile" marginRight={[2, null, null, 3]}>
                <FlatButton
                  className="use-editor"
                  onClick={() => {
                    if (activeMenu !== 'newsletter') {
                      setActiveMenu('newsletter')
                      trackEvent({ category: 'newsletter', label: 'open' })
                    }
                  }}
                  style="small"
                  shadow
                >
                  <FormattedMessage
                    id="nyhetsbrev"
                    defaultMessage="Nyhetsbrev"
                  />
                </FlatButton>
              </Box>
            )}
            <FlatButton
              className="use-editor"
              onClick={() => setActiveMenu('nav')}
              style="small"
              shadow
            >
              <FormattedMessage id="meny" defaultMessage="Meny" />
            </FlatButton>
          </Flex>
        </Flex>
      </Container>

      {/* Menu */}
      <Box
        className={
          activeMenu === 'nav'
            ? [styles.menu, styles.active].join(' ')
            : styles.menu
        }
      >
        <ScrollLock isActive={activeMenu === 'nav'}>
          <Box className={styles.content}>
            <Flex
              className={styles.contentInner}
              height="100%"
              flexDirection="column"
              paddingY={[6, null, null, 7]}
              paddingX={[6, null, null, 8]}
            >
              <Flex
                marginBottom={[6, null, null, 11]}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box as="span" className={styles.label}>
                  {label && label}
                </Box>
                <FlatButton
                  onClick={() => setActiveMenu('')}
                  style="small"
                  className={classnames(styles.close, 'use-editor')}
                  purpleBorder
                >
                  <FormattedMessage id="stäng" defaultMessage="Stäng" />
                </FlatButton>
              </Flex>

              {main_nav?.length > 0 && (
                <Box
                  as="nav"
                  className={styles.mainNav}
                  marginTop={[8, null, null, 10]}
                >
                  {main_nav.map((link: any, index: number) => {
                    const { title, url } = getLinkAttributes(link)
                    return (
                      <Box
                        key={`header-main-nav-${index}`}
                        marginBottom={
                          index === main_nav.length - 1 ? 0 : [4, null, null, 5]
                        }
                      >
                        <LinkComponent
                          to={url}
                          onClick={() => setActiveMenu('')}
                        >
                          {title}
                        </LinkComponent>
                      </Box>
                    )
                  })}
                </Box>
              )}
              <Flex marginTop="auto" flexDirection="column">
                {second_nav?.length > 0 && (
                  <Box as="nav" className={styles.secondNav}>
                    {second_nav.map((link: any, index: number) => {
                      const { title, url } = getLinkAttributes(link)
                      return (
                        <Box
                          key={`header-second-nav-${index}`}
                          marginBottom={['10px', null, null, 3]}
                        >
                          <LinkComponent
                            to={url}
                            onClick={() => setActiveMenu('')}
                          >
                            {title}
                          </LinkComponent>
                        </Box>
                      )
                    })}
                    {ticket_link && (
                      <Box marginBottom={['10px', null, null, 3]}>
                        <LinkComponent
                          to={ticket_link}
                          onClick={() => setActiveMenu('')}
                        >
                          <FormattedMessage
                            id="köp-biljetter"
                            defaultMessage="Köp biljetter"
                          />
                        </LinkComponent>
                      </Box>
                    )}
                    {newsletter_button && (
                      <Box marginBottom={['10px', null, null, 3]}>
                        <Box
                          as="button"
                          className="use-editor"
                          onClick={() => {
                            if (activeMenu !== 'newsletter') {
                              setActiveMenu('newsletter')
                              trackEvent({
                                category: 'newsletter',
                                label: 'open',
                              })
                            }
                          }}
                        >
                          <FormattedMessage
                            id="nyhetsbrev"
                            defaultMessage="Nyhetsbrev"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Flex>
            </Flex>
          </Box>
        </ScrollLock>
      </Box>
    </SbEditable>
  )
}

export default Header
