export const getLinkAttributes = (link: any) => {
    const url = link?.url?.cached_url
      ? link.url.cached_url
      : link?.url?.email
      ? `mailto:${link.url.email}`
      : ''
    const urlWithAnchor = link?.url?.anchor ? `${url}#${link.url.anchor}` : url
    const title = link?.title ? link.title : ''
  
    return {
      url: urlWithAnchor,
      title: title,
    }
  }
  