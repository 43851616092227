import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { Container } from 'src/components/UI/Grid/Grid'
import * as styles from './Footer.module.scss'
import { Props } from './types'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import { useRecoilState } from 'recoil'
import { activeMenuState } from 'src/recoil/atoms'
import LanguagePicker from 'src/components/LanguagePicker/LanguagePicker'
import Image from 'src/components/UI/Image/Image'
import MarketHype from 'src/components/MarketHype/MarketHype'
import PartnerList from 'src/storyblok/Modules/PartnerList/PartnerList'

const Footer = ({ blok, graphics }: Props): JSX.Element => {
  const { logotypes, links, text, partner_list } = blok
  const [, setActiveMenu] = useRecoilState(activeMenuState)
  const { rose_5, rose_4, butterfly }: any = graphics ? graphics : {}

  return (
    <SbEditable content={blok}>
      <MarketHype />

      <Container as="footer" className={styles.wrapper}>
        {logotypes && logotypes?.length > 0 && (
          <React.Fragment>
            {rose_5 && (
              <Image
                src={rose_5.filename}
                alt={rose_5.title}
                className={[styles.rose5].join(' ')}
              />
            )}
            {rose_4 && (
              <Image
                src={rose_4.filename}
                alt={rose_4.title}
                className={[styles.rose8].join(' ')}
              />
            )}
            {butterfly && (
              <Image
                src={butterfly.filename}
                alt={butterfly.title}
                className={styles.butterfly}
              />
            )}
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              paddingTop={[10]}
            >
              {logotypes.map((blok: any) => {
                const { title, logotypes } = blok
                return (
                  <Flex
                    marginBottom={[12, null, null, 20]}
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    className={styles.logotypes}
                  >
                    <Box marginBottom={[5, null, null, 6]}>{title}</Box>
                    {logotypes && logotypes?.length > 0 && (
                      <Flex>
                        {logotypes.map((logotype: any) => {
                          const { image, link } = logotype
                          const { title, url } = getLinkAttributes(link)
                          return (
                            <Box
                              key={`footer-link-${title}`}
                              marginX={[5, null, null, 8]}
                              marginBottom={[1, null, 0]}
                            >
                              {url ? (
                                <LinkComponent
                                  className={styles.logotype}
                                  to={url}
                                >
                                  <Image
                                    className={styles.logotypeImage}
                                    src={image.filename}
                                    alt={title}
                                  />
                                </LinkComponent>
                              ) : (
                                <Box className={styles.logotype}>
                                  <Image
                                    className={styles.logotypeImage}
                                    src={image.filename}
                                    alt={title}
                                  />
                                </Box>
                              )}
                            </Box>
                          )
                        })}
                      </Flex>
                    )}
                  </Flex>
                )
              })}
            </Flex>
          </React.Fragment>
        )}
        {partner_list &&
          partner_list.length > 0 &&
          partner_list.map((blok: any) => (
            <PartnerList blok={blok} key={blok._uid} />
          ))}
        <Flex
          justifyContent={['flex-end', null, null, 'center']}
          alignItems={['center', null, null, 'center']}
          flexDirection={['column', null, null, 'row']}
          paddingY={[8, null, null, 8]}
        >
          <Flex
            flexWrap={'wrap'}
            justifyContent={['center', null, 'flex-start']}
            flexDirection={['column', null, null, 'row']}
            marginBottom={[0, null, null, 0]}
          >
            {links &&
              links?.length > 0 &&
              links.map((link: any) => {
                const { component } = link

                const { title, url } = getLinkAttributes(link)
                return (
                  <Box
                    key={`footer-link-${title}`}
                    className={styles.link}
                    marginRight={[3, null, null, 8]}
                    marginLeft={[3, null, null, 0]}
                    marginBottom={[4, null, null, 0]}
                    textAlign={['center', null, null, 'left']}
                  >
                    {component === 'link' ? (
                      <LinkComponent to={url}>{title}</LinkComponent>
                    ) : (
                      <Box
                        onClick={() => {
                          setActiveMenu('newsletter')
                        }}
                        as="button"
                      >
                        {title}
                      </Box>
                    )}
                  </Box>
                )
              })}
          </Flex>
          <Flex
            alignItems="center"
            flexDirection={['column', null, null, 'row']}
          >
            {/* <Box
              marginRight={[0, null, null, 10]}
              marginBottom={[4, null, null, 0]}
            >
              <LanguagePicker />
            </Box> */}
            {text && (
              <Box
                className={styles.info}
                textAlign={['center', null, null, 'left']}
              >
                {text}
              </Box>
            )}
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default Footer
