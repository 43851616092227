type Props = {
    action? : string
    category : string
    label : string
    value? : number
}


export const trackEvent = ({action = "click", category = '', label = '', value = 1 } : Props) => {
    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        })
    }
}