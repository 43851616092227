import React from 'react'
import { Box, Flex } from 'reflexbox'
import * as styles from './PartnerList.module.scss'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Image from 'src/components/UI/Image/Image'

const PartnerList = ({ blok }: any) => {
  const { columns } = blok

  return (
    <SbEditable content={blok}>
      <Flex paddingY={6} className={styles.partners}>
        <Container>
          {columns &&
            columns.map((column: any) => (
              <Flex
                width={1}
                alignItems="center"
                className={styles.column}
                marginBottom={['52px', null, null, '80px']}
                flexDirection={['column', null, null, 'column']}
              >
                <span className={styles.title}>{column.title}</span>
                <div className={styles.list}>
                  {column &&
                    column.partners &&
                    column.partners.map((partner: any) =>
                      partner && partner.link ? (
                        <LinkComponent
                          to={partner.link ? partner.link.cached_url : ''}
                          key={partner.uid}
                        >
                          <div>
                            {partner.logotype && (
                              <Image
                                src={partner.logotype.filename}
                                alt={partner.logotype.title}
                              />
                            )}
                          </div>
                        </LinkComponent>
                      ) : (
                        <div key={partner.uid}>
                          <div>
                            {partner.logotype && (
                              <Image
                                src={partner.logotype.filename}
                                alt={partner.logotype.title}
                              />
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </Flex>
            ))}
        </Container>
      </Flex>
    </SbEditable>
  )
}

export default PartnerList
