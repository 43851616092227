// extracted by mini-css-extract-plugin
export var overlay = "Header-module--overlay--2V_Bu";
export var active = "Header-module--active--2MvVO";
export var logotype = "Header-module--logotype--1YC2G";
export var header = "Header-module--header--2r2e8";
export var fadeIn = "Header-module--fadeIn--2Ro__";
export var navigation = "Header-module--navigation--eCQNY";
export var menu = "Header-module--menu--1d2uz";
export var close = "Header-module--close--2agAE";
export var label = "Header-module--label--emkPb";
export var content = "Header-module--content--1Xr7L";
export var mainNav = "Header-module--mainNav--2RBVb";
export var secondNav = "Header-module--secondNav--Izi2z";