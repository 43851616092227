import React from 'react'
import isNode from 'is-node'
import { Helmet } from 'react-helmet'

import { formatImage } from 'src/helpers/formatImage'

import { Props, Breakpoints } from './types'

const getSrcSet = (src: string, ratio: number) => {
  const array = []
  array.push(`${formatImage(src, 100, ratio)} 100w`)
  array.push(`${formatImage(src, 200, ratio)} 200w`)
  array.push(`${formatImage(src, 400, ratio)} 400w`)
  array.push(`${formatImage(src, 800, ratio)} 800w`)
  array.push(`${formatImage(src, 1200, ratio)} 1200w`)
  array.push(`${formatImage(src, 1600, ratio)} 1600w`)
  array.push(`${formatImage(src, 2400, ratio)} 2400w`)
  return array.join(', ')
}

export const breakpoints: Breakpoints = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
}

class Image extends React.Component<Props> {
  imageRef: any
  observer: any

  static defaultProps = {
    sizes: {
      mobile: '100vw',
      tablet: '50vw',
      desktop: '50vw',
    },
  }

  constructor(props: Props) {
    super(props)
    if (!isNode) {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: '0px 0px 500px 0px',
      })
    }
  }

  observeHandler = (entries: any) => {
    const { onImageLoaded } = this.props
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const src = this.imageRef.getAttribute('data-src') || ''
        const srcSet = this.imageRef.getAttribute('data-srcset') || ''
        this.imageRef.setAttribute('src', src)
        this.imageRef.setAttribute('srcset', srcSet)
        this.imageRef.onload = () => {
          // this.imageRef.style.opacity = 1
          if (onImageLoaded) {
            onImageLoaded()
          }
        }
        this.observer.unobserve(entry.target)
      }
    }
  }

  get placeholder() {
    const { ratio } = this.props
    if (!ratio) {
      // Return an empty pixel
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg=='
    }
    const viewBox = `0 0 ${ratio} 1`
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      '#FFFFFF00'
    )}" width="100%" height="100%" /></svg>`
  }

  getSizes() {
    const { sizes } = this.props
    if (typeof sizes === 'string') {
      return sizes
    } else if (typeof sizes === 'object') {
      return Object.keys(sizes)
        .map((width: string) => `${breakpoints[width]} ${sizes[width]}`)
        .join(',')
    }
    return ''
  }

  render() {
    let { src, alt, ratio, className, isCritical, preload, style } = this.props
    if (!src) return null
    let noScriptClass = className

    if (className) {
      className = className + ' o-image'
    } else {
      className = 'o-image'
    }
    if (isCritical) {
      className = className + ' critical'
    }

    const srcSet = getSrcSet(src, ratio)
    const formatSrc = formatImage(src, 2400, ratio)
    let webpChecked = false
    let webpAccepted = false

    if (!isNode && document) {
      webpChecked = document
        .getElementsByTagName('html')[0]
        .classList.contains('webp-checked')
      webpAccepted = document
        .getElementsByTagName('html')[0]
        .classList.contains('support-webp')
    }
    if (typeof alt !== 'string') {
      alt = ''
    }

    if (src.endsWith('.svg')) {
      return (
        <img
          ref={(x) => {
            if (x && !isCritical) {
              this.observer.observe(x)
              this.imageRef = x
            }
          }}
          className={className}
          // sizes={this.getSizes()}
          data-src={src}
          src={isCritical ? src : this.placeholder}
          alt={alt}
        />
      )
    }

    return (
      <React.Fragment>
        {isNode && (
          <React.Fragment>
            <noscript>
              <img
                className={noScriptClass}
                sizes={this.getSizes()}
                srcSet={srcSet}
                src={formatSrc}
                style={style}
                alt={alt}
              />
            </noscript>
          </React.Fragment>
        )}
        {preload && (
          <Helmet>
            <link
              rel="preload"
              as="image"
              href={formatSrc}
              imagesrcset={srcSet}
              style={style}
              imagesizes={this.getSizes()}
            />
          </Helmet>
        )}
        {webpChecked &&
          (webpAccepted ? (
            <img
              ref={(x) => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              style={style}
              className={className}
              data-srcset={srcSet}
              sizes={this.getSizes()}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ) : (
            <img
              ref={(x) => {
                if (x && !isCritical) {
                  this.observer.observe(x)
                  this.imageRef = x
                }
              }}
              className={className}
              data-srcset={srcSet}
              sizes={this.getSizes()}
              style={style}
              data-src={formatSrc}
              src={isCritical ? formatSrc : this.placeholder}
              alt={alt}
            />
          ))}
      </React.Fragment>
    )
  }
}

export default Image
