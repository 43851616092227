import { atom } from 'recoil'

export const activeMenuState = atom({
  key: 'activeMenu',
  default: '' as string,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newActiveMenu) => {
        if (document) {
          if (newActiveMenu) {
            document.body.classList.add('prevent-overflow')
            if (newActiveMenu === 'nav') {
              // document.body.classList.add('enable-overflow-desktop')
            } else {
              // document.body.classList.remove('enable-overflow-desktop')
            }
          } else {
            document.body.classList.remove('prevent-overflow')
            document.body.classList.remove('enable-overflow-desktop')
          }
        }
      })
    },
  ],
})

export const storyState = atom({
  key: 'story',
  default: {} as {},
})

// Set from Storyblok
export const artistsState = atom({
  key: 'artists',
  default: [''] as [string],
})


